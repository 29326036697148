<template>
  <v-layout align-center justify-center>
    <v-flex xs12 lg10>
      <v-layout column justify-center align-center class="mb-4">
        <h1 class="greeting">idenfit'e<br />Hoş Geldiniz</h1>
        <h2 class="title">Personel Girişi</h2>
      </v-layout>
      <v-layout column>
        <v-flex xs12>
          <v-card flat :disabled="loading" class="d-flex justify-center">
            <v-form ref="form" style="max-width: 400px; width: 100%" @submit.prevent>
              <validation-observer ref="loginForm">
                <validation-provider name="email" rules="required" v-slot="{ errors }">
                  <v-text-field
                    @keydown.enter="focusPassword"
                    v-model.trim="formData.username"
                    :error-messages="errors"
                    :label="$t('auth.email')"
                    :disabled="loading"
                    color="tertiary"
                    autocomplete
                    autofocus
                    outlined
                    clearable
                    class="mb-3"
                  />
                </validation-provider>
                <validation-provider name="password" rules="required" v-slot="{ errors }">
                  <v-text-field
                    v-model.trim="formData.password"
                    clearable
                    outlined
                    ref="password"
                    color="tertiary"
                    autocomplete="current-password"
                    :type="showPassword ? 'text' : 'password'"
                    :disabled="loading"
                    :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                    :error-messages="errors"
                    :label="$t('auth.password')"
                    @keydown.enter="login"
                    @click:append="showPassword = !showPassword"
                  />
                </validation-provider>
              </validation-observer>
              <v-btn
                :loading="loading"
                :disabled="loading"
                color="primary"
                block
                large
                depressed
                class="mt-5"
                @click.native="loader = 'loading'"
                @click.stop="login"
              >
                {{ $t("auth.sign_in") }}
              </v-btn>
              <div class="d-flex align-center my-3">
                <v-divider inset style="margin-right: 25px !important" class="ml-0" />
                {{ $t("or") }}
                <v-divider inset style="margin-left: 25px !important" />
              </div>
              <div ref="googleButtonContainer"></div>
              <button type="button" class="microsoft-button mt-2" @click.stop="handleMicrosoftResponse">
                <img src="/logos/microsoft-logo.svg" width="18" height="18" />
                <span>{{ $t("continueWithMicrosoft") }}</span>
              </button>
            </v-form>
          </v-card>
        </v-flex>
        <v-flex xs12 class="mt-3">
          <v-btn block text :disabled="loading" depressed :to="{ name: 'forgot' }">
            {{ $t("auth.forget_password") }}
          </v-btn>
        </v-flex>
        <v-flex xs12 class="mt-3">
          <GoogleRecaptcha :sitekey="siteKey" action-name="login" ref="recaptcha" />
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
  import { mapGetters } from "vuex";
  import TravisAes from "@/lib/crypto/TravisAes";
  import { SSO_IDENTITY_PROVIDER } from "@/helpers/enums";
  import AzureClient from "@/utils/auth/azure/AzureClient";
  import JSEncrypt from "jsencrypt";

  const jsEncrypt = new JSEncrypt();
  jsEncrypt.setPublicKey(process.env.VUE_APP_PASSWORD_PUBLIC_KEY);
  const GOOGLE_SSO_CLIENT_ID = "253452170434-mna9l658pf8qu3mq0bhqomf6c1lok5pj.apps.googleusercontent.com";

  export default {
    name: "login",
    props: {
      redirect: String
    },
    components: {
      GoogleRecaptcha: () => import("./components/GoogleRecaptcha")
    },
    data() {
      return {
        aes: new TravisAes(),
        azureClient: new AzureClient(),
        formData: {
          username: null,
          password: null
        },
        showPassword: false,
        siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY
      };
    },
    computed: {
      ...mapGetters({
        loading: "auth/loading"
      })
    },
    methods: {
      login() {
        this.$refs.loginForm.validate().then(async (valid) => {
          if (valid) {
            const recaptchaResponseToken = await this.$refs.recaptcha.generateManualToken();
            if (!recaptchaResponseToken) {
              this.$helpers.showNotification("robot_validation", "error");
              return false;
            }
            this.$store.commit("auth/SET_LOADING", true);
            this.$store
              .dispatch("auth/LOGIN", {
                ...this.formData,
                password: jsEncrypt.encrypt(this.formData.password),
                captcha: recaptchaResponseToken
              })
              .then((firstLogin) => {
                this.$store
                  .dispatch("auth/FETCH_PENDING_FEEDBACKS")
                  .then((pendingFeedbacks) => {
                    if (firstLogin) {
                      this.$router.replace({ name: "first_login" });
                    } else if (pendingFeedbacks.length > 0) {
                      this.$router.replace({ name: "pending_feedbacks" });
                    } else if (this.$route.params.redirect) {
                      this.$router.push(this.$route.params.redirect);
                    } else {
                      this.$router.replace({ name: "dashboard" });
                    }
                  })
                  .catch(console.error)
                  .finally(() => this.$store.commit("auth/SET_LOADING", false));
              })
              .catch((error) => {
                this.$store.commit("auth/SET_LOADING", false);
                this.$helpers.showNotification(this.$t("auth.invalid_email_or_password"), "error", true);
              });
          }
        });
      },
      focusPassword() {
        this.$nextTick(() => this.$refs.password.focus());
      },
      handleResize() {
        this.renderGoogleButton(window.google);
      },
      loginWithSSO(identityProvider, accessToken) {
        this.$store.commit("auth/SET_LOADING", true);
        this.$store
          .dispatch("auth/LOGIN_WITH_SSO", { identityProvider, accessToken })
          .then((firstLogin) => {
            this.$store
              .dispatch("auth/FETCH_PENDING_FEEDBACKS")
              .then((pendingFeedbacks) => {
                if (firstLogin) {
                  this.$router.replace({ name: "first_login" });
                } else if (pendingFeedbacks.length > 0) {
                  this.$router.replace({ name: "pending_feedbacks" });
                } else if (this.$route.params.redirect) {
                  this.$router.push(this.$route.params.redirect);
                } else {
                  this.$router.replace({ name: "dashboard" });
                }
              })
              .catch(console.error)
              .finally(() => this.$store.commit("auth/SET_LOADING", false));
          })
          .catch((error) => this.$helpers.showNotification(error, "error", true));
      },
      handleGoogleResponse(response) {
        this.loginWithSSO(SSO_IDENTITY_PROVIDER.GOOGLE, response.credential);
      },
      async handleMicrosoftResponse() {
        const accessToken = await this.azureClient.signIn();
        if (accessToken) {
          this.loginWithSSO(SSO_IDENTITY_PROVIDER.MICROSOFT, accessToken);
        }
      },
      renderGoogleButton(google) {
        google.accounts.id.renderButton(this.$refs.googleButtonContainer, {
          theme: "outline",
          size: "large",
          shape: "pill",
          text: "continue_with",
          width: this.$refs.form.$el.clientWidth,
          locale: navigator.language
        });
      },
      initGoogleSSO(google) {
        google.accounts.id.initialize({
          client_id: GOOGLE_SSO_CLIENT_ID,
          callback: this.handleGoogleResponse,
          ux_mode: "popup"
        });
        this.renderGoogleButton(google);
        google.accounts.id.prompt();
      }
    },
    mounted() {
      window.addEventListener("resize", this.handleResize);
      this.initGoogleSSO(window.google);
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.handleResize);
    }
  };
</script>

<style scoped lang="scss">
  .greeting {
    font-family: "Quicksand", sans-serif;
    line-height: 120%;
    font-weight: 500;
    font-size: 36px;
    text-align: center;
    color: var(--v-primary-base);
  }
  .title {
    font-family: "Quicksand", sans-serif;
    font-size: 24px;
    font-weight: 500;
    margin-top: 18px;
  }

  .microsoft-button {
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 20px;
    border: 1px solid #dadce0;
    height: 40px;
    transition: background-color 0.218s;
    &:hover {
      border-color: #d2e3fc;
      background: rgba(66, 133, 244, 0.04);
    }
    img {
      margin-left: 12px;
    }
    span {
      flex-grow: 1;
      text-align: center;
      color: #3c4043;
      cursor: pointer;
      font-family: "Google Sans", arial, sans-serif !important;
      font-size: 14px !important;
      letter-spacing: 0.25px !important;
      white-space: nowrap;
      font-weight: 500 !important;
    }
  }
</style>
