export const authConfig = {
  auth: {
    clientId: "33288a7b-58be-4ad1-beb7-799fc5a76351",
    authority: `https://login.microsoftonline.com/common`,
    redirectUri: `${document.location.origin}/auth/login`
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false
  }
};

export const loginRequest = {
  scopes: ["User.Read"],
  prompt: "select_account"
};
