import * as msal from "@azure/msal-browser";
import { authConfig, loginRequest } from "./authConfig.js";

export default class AzureClient {
  constructor() {
    this.msalInstance = new msal.PublicClientApplication(authConfig);
    this.accountId = null;
  }

  signIn() {
    return this.msalInstance
      .loginPopup(loginRequest)
      .then((response) => {
        this.accountId = response.account.homeAccountId;
        return response.accessToken;
      })
      .catch(console.error);
  }

  signOut() {
    const logoutRequest = {
      account: this.msalInstance.getAccountByHomeId(this.accountId)
    };
    this.msalInstance.logoutPopup(logoutRequest);
  }
}
